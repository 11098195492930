export default [
    {
        path: '/projects/beatsomeone-scan/bso-introduction',
        name: 'bso-introduction',
        component: () => import('@/views/projects/beatSomeoneScan/BsoIntroduction.vue'),
    },
    {
        path: '/projects/enter-block/enter-introduction',
        name: 'enter-introduction',
        component: () => import('@/views/projects/enterBlock/EnterIntroduction.vue'),
    }
]