export default [
    {
        path: '/components/loading',
        name: 'loading',
        component: () => import('@/views/components/Loading.vue'),
        meta: {
            breadcrumb: [
                {
                    text: 'Components'
                },
                {
                    text: 'Loading',
                    active: true,
                }
            ]
        }
    },
    {
        path: '/components/button',
        name: 'button',
        component: () => import('@/views/components/Button.vue'),
        meta: {
            breadcrumb: [
                {
                    text: 'Components'
                },
                {
                    text: 'Button',
                    active: true,
                }
            ]
        }
    },
    {
        path: '/components/card',
        name: 'card',
        component: () => import('@/views/components/Card.vue'),
        meta: {
            breadcrumb: [
                {
                    text: 'Components'
                },
                {
                    text: 'Card',
                    active: true,
                }
            ]
        }
    },
    {
        path: '/components/form',
        name: 'form',
        component: () => import('@/views/components/Form.vue'),
        meta: {
            breadcrumb: [
                {
                    text: 'Components'
                },
                {
                    text: 'Form',
                    active: true,
                }
            ]
        }
    }
]