export default [
    {
        path: '/',
        name: 'introduction',
        component: () => import('@/views/Home.vue'),
        meta: {
            pageTitle: 'Introduction',
            breadcrumb: [
                {
                    text: 'Introduction',
                    active: true,
                },
            ],
        },
    },
]