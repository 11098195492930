export default [
    {
        path: '/development/api',
        name: 'api',
        component: () => import('@/views/development/Api.vue'),
        meta: {
            breadcrumb: [
                {
                    text: 'Development'
                },
                {
                    text: ' API',
                    active: true,
                }
            ]
        }
    },
    {
        path: '/development/routing',
        name: 'routing',
        component: () => import('@/views/development/Routing.vue'),
        meta: {
            breadcrumb: [
                {
                    text: 'Development'
                },
                {
                    text: ' Routing',
                    active: true,
                }
            ]
        }
    },
    {
        path: '/development/responsive-web',
        name: 'responsive-web',
        component: () => import('@/views/development/ResponsiveWeb.vue'),
        meta: {
            breadcrumb: [
                {
                    text: 'Development'
                },
                {
                    text: ' Responsive Web',
                    active: true,
                }
            ]
        }
    },
    {
        path: '/development/pagination',
        name: 'pagination',
        component: () => import('@/views/development/Pagination.vue'),
        meta: {
            breadcrumb: [
                {
                    text: 'Development'
                },
                {
                    text: ' Pagination',
                    active: true,
                }
            ]
        }
    },
    {
        path: '/development/transition',
        name: 'transition',
        component: () => import('@/views/development/Transition.vue'),
        meta: {
            breadcrumb: [
                {
                    text: 'Development'
                },
                {
                    text: ' Transition',
                    active: true,
                }
            ]
        }
    },
    {
        path: '/development/layout',
        name: 'layout',
        component: () => import('@/views/development/Layout.vue'),
        meta: {
            breadcrumb: [
                {
                    text: 'Development'
                },
                {
                    text: ' Layout',
                    active: true,
                }
            ]
        }
    },
    {
        path: '/development/folder-structure',
        name: 'folder-structure',
        component: () => import('@/views/development/FolderStructure.vue'),
        meta: {
            breadcrumb: [
                {
                    text: 'Development'
                },
                {
                    text: ' Folder Structure',
                    active: true,
                }
            ]
        }
    }

]