import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import introduction from './routes/introduction';
import projects from './routes/projects';
import development from "./routes/development";
import components from "./routes/components";

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to) {
    if(to.hash) {
      return {
        selector: to.hash,
        offset: { x: 0, y: 200 },
        behavior: 'smooth',
      }
    }
    return { x: 0, y: 0 }
  },
  routes: [
    {path: '/', redirect: {name: 'introduction'}},
      ...introduction,
      ...projects,
      ...development,
      ...components,
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
